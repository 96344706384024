<template>
  <div>
    <b-row>
      <b-col xl="6" md="6">
        <select-card />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SelectCard from './home-components/SelectCard.vue';

export default {
  components: {
    SelectCard,
  },
}
</script>

<style>

</style>
