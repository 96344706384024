<template>
  <b-row>
    <b-col cols="12" xl="6" lg="6" md="12" v-for="item in selectCardList" :key="item.route">
      <b-card class="custom-select-card"
          @click="$router.push({ name: item.route })">
        <b-media no-body>
          <b-media-aside class="mr-2">
            <b-avatar
              size="48"
              :variant="item.variant">
              <feather-icon
                size="24"
                :icon="item.icon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h5 class="font-weight-bolder mb-0">
              {{ $t(item.title) }}
            </h5>
          </b-media-body>
        </b-media>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>

export default {
  data() {
    return {
      selectCardList: [
        { route: 'manage-kullanici', icon: 'UserIcon', variant: 'light-info', title: 'Kullanıcılar' },
        { route: 'manage-musteri', icon: 'UserIcon', variant: 'light-success', title: 'Müşteriler' },
        { route: 'manage-urun', icon: 'ArchiveIcon', variant: 'light-warning', title: 'Ürün Yönetim' },
        { route: 'manage-siparis', icon: 'GridIcon', variant: 'light-primary', title: 'Sipariş Yönetim' },
        { route: 'manage-cariekstra', icon: 'FileTextIcon', variant: 'light-info', title: 'Cari Ekstre' },
        { route: 'manage-duyuru', icon: 'Volume2Icon', variant: 'light-warning', title: 'Duyuru Yönetim' },
        { route: 'manage-webapi-kullanici', icon: 'CloudIcon', variant: 'light-danger', title: 'WebApi Kullanıcıları' },
        { route: 'manage-db-kullanici', icon: 'DatabaseIcon', variant: 'light-danger', title: 'Veri Tabanı Kullanıcıları' },
      ],
    }
  },
};
</script>

<style scoped>
.custom-select-card {
  cursor: pointer;
}

.custom-select-card:hover {
    color:#ffffff;
    background-color: #7367f0;
}

.custom-select-card:hover h5 {
    color:#ffffff;
}

.custom-select-card:hover .b-avatar.badge-light-primary {
  background-color: rgb(233 232 241 / 59%);
  color: #f6f6f9;
}

.custom-select-card:hover .b-avatar.badge-light-success {
  background-color: rgb(233 232 241 / 59%);
  color: #f6f6f9;
}

.custom-select-card:hover .b-avatar.badge-light-danger {
  background-color: rgb(233 232 241 / 59%);
  color: #f6f6f9;
}

.custom-select-card:hover .b-avatar.badge-light-info {
  background-color: rgb(233 232 241 / 59%);
  color: #f6f6f9;
}

@media screen and (max-width: 1584px) {
  .custom-select-card .card-body {
    padding: 0.9rem;
  }
  .custom-select-card h5 {
    font-size: 12px;
  }
}
</style>
